import React, { useState } from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Lottie from "lottie-react";
import morty from "../assets/loader/morty.json";
import "react-responsive-modal/styles.css";
import "./customModalStyles.css";
import { Modal } from "react-responsive-modal";

const Contact = () => {
  const [open, setOpen] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      setOpen(true);
      await axios.post("https://aktuelim-backend.vercel.app/api/send-email", {
        ...data,
      });
      setOpen(false);
      toast.success("Mesajınız İletildi!");
      reset();
    } catch (error) {
      console.error(error);
      setOpen(false);
      toast.error("Bir Hata Oluştu!");
    }
  };

  const Input = ({ label, register, required, placeholder, type }) => (
    <>
      <input
        type={type}
        placeholder={placeholder}
        className="bg-transparent border-b py-3 outline-none w-full placeholder:text-white/50 focus:border-accent transition-all"
        {...register(label, { required })}
      />
      {errors && errors[label] && (
        <span className="text-red-500">Bu alan gerekli</span>
      )}
    </>
  );

  const TextArea = ({ label, register, required, placeholder }) => (
    <>
      <textarea
        maxLength="4000"
        placeholder={placeholder}
        className="bg-transparent border-b py-12 outline-none w-full placeholder:text-white/50 focus:border-accent transition-all resize-none"
        {...register(label, { required })}
      />
      {errors && errors[label] && (
        <span className="text-red-500">Bu alan gerekli</span>
      )}
    </>
  );

  return (
    <section className="py-16 lg:section" id="contact">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row">
          <motion.div
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 flex justify-start items-center"
          >
            <div>
              <h4 className="text-xl uppercase text-accent font-medium mb-2 tracking-wide">
                ıletişim
              </h4>
              <h2 className="text-[45px] lg:text-[90px] leading-none mb-12">
                Benimle İletişime <br /> Geçin.
              </h2>
            </div>
          </motion.div>
          <motion.form
            onSubmit={handleSubmit(onSubmit)}
            variants={fadeIn("left", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 border rounded-2xl flex flex-col gap-y-6 p-6 pb-0 items-center ss:mb-20 sm:mb-20 md:mb-20 lg:mb-0 xl:mb-0"
          >
            <Input
              type="text"
              label="nameSurname"
              register={register}
              required
              placeholder="İsim Soyisim Giriniz."
            />
            <Input
              type="email"
              label="mail"
              register={register}
              required
              placeholder="E-Posta Giriniz."
            />
            <TextArea
              label="explanation"
              register={register}
              required
              placeholder="Mesajınızı Giriniz."
            />
            <button className="btn btn-lg" type="submit">
              Gönder
            </button>
            <Modal
              open={open}
              showCloseIcon={false}
              center
              classNames={{
                overlay: "customOverlay",
                modal: "customModal",
              }}
            >
              <Lottie animationData={morty} />
            </Modal>
            <ToastContainer position="bottom-center" autoClose={2000} />
          </motion.form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
